import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import "./Plan.css";
import { LabelContext } from "../../hooks/InputFeild";

export default function Plan(props) {
  let labelContext = useContext(LabelContext);

  return (
    <Card
      sx={{
        border: props.choosen ? "2px solid #fcd10d" : "",
        marginBottom: "20px",
      }}
      className={"card-plan choosen-" + props.choosen}
      elevation={props.choosen ? 0 : 5}
      onClick={() => {
        labelContext.choosePlan(props.data, props.step);
      }}
    >
      <CardActionArea
        sx={{ display: "flex", alignItems: "stretch", flexWrap: "wrap" }}
      >
        <CardMedia
          component="img"
          sx={{ objectFit: "cover" }}
          image={props.data.image}
          className="planimg"
          alt="green iguana"
        />
        <CardContent sx={{ p: "15px" }} className="plandesc">
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            fontSize="1rem"
            className="packageTitle"
          >
            {props.data.package_name}
          </Typography>
          <Typography
            mb="5px"
            className="packageDescription"
            variant="body2"
            color="text.secondary"
          >
            <pre>{props.data.package_description}</pre>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
