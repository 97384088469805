import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import i18next, { t } from 'i18next';
import { deleteAcc } from '../../api/api';
import { authActions } from '../hooks/auth';
import { useDispatch } from 'react-redux';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '15px',
  p: 4,
};

export default function DeleteModal({open, handleClose}) {
    let dispatch = useDispatch();

    function handleDelete(){
        deleteAcc().then(res => {
            dispatch(authActions.logoutHandler());
            
        })

    }

  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography mb={5} textAlign={i18next.language === 'ar' ? "right" :"left"} id="transition-modal-title" variant="h6" component="h2">
              {t("Are you sure you want to delete your account ?!")}
            </Typography>
            <div style={{display: "flex", justifyContent: i18next.language === 'ar' ? "flex-start" : "flex-end"}}>
            <Button onClick={handleClose} style={{marginRight: "15px" }} type="submit" size="normal" variant="contained">{t("Back")}</Button>
            <Button onClick={handleDelete} className="danger" type="button" size="normal" variant="contained">{t("Delete Account")}</Button>

            </div>

          </Box>
        </Fade>
      </Modal>
  );
}
