import React from "react";

import "./Left.css"

export default function Left(){

    return(

        <React.Fragment>
            <div style={{backgroundImage: "url('/images/bg.jpg')"}}>
                <div className="overlay"></div>
            </div>
        </React.Fragment>
        
    )

}