import React, { useContext, useEffect, useState } from "react";
import { LabelContext } from "../hooks/InputFeild";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { getRoomTypes } from "../../api/api";
import { useTranslation } from "react-i18next";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "100%",
}));

function UnitSpaces() {
  const value = useContext(LabelContext);
  const FormWizard = value.inputs.Rooms;
  const { t } = useTranslation();
  const [Rooms, setRooms] = useState([]);

  useEffect(() => {
    getRoomTypes().then(
      (res) => {
        let actualArea = value.inputs.details.unitArea * value.inputs.details.ActualAreaPercent;

        const unitRooms = {
          wc: {},
          kitchen: {},
          room: {},
          wc_guest: {},
        }

        res.data.Rooms.forEach(option => {
          const userAlreadyChangedValue = value.inputs.unitRooms[option.category][option.id] ?
          ((option.is_required && value.inputs.unitRooms[option.category][option.id].num > 1) || 
          (!option.is_required && value.inputs.unitRooms[option.category][option.id].num > 0)) : false

          unitRooms[option.category] = {
            ...unitRooms[option.category],
            [option.id]: {
              ...(userAlreadyChangedValue ? {num: value.inputs.unitRooms[option.category][option.id].num} : {num: option.is_required ? 1 : 0}),
              roomObj: option,
              area: option.fixed
                ? option.fixedArea
                : option.percentage * actualArea,
            }
          }
           
        })

        value.updateInputs({
          ...value.inputs,
          unitRooms
        })

        setRooms(res.data.Rooms);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Item>
          <Typography
            className="mr title-step"
            gutterBottom
            variant="h5"
            component="h5"
          >
            {t("List the rooms or spaces you need in your unit ")}
          </Typography>

          <Typography
            className="note-fourth"
            gutterBottom
            variant="p"
            component="p"
          >
            <span>
              1. Please note that we consider the reception area as a default
              space in our calculations. As such, it is not necessary to include
              it in the list of required spaces.
            </span>
            <span>
              2. Select the spaces you need to achieve your dream home, not the
              current setup.
            </span>
          </Typography>

          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "30ch" },
              display: "flex",
              justifyContent: "center",
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              {Rooms.map((option) => (
                <React.Fragment key={option.id}>
                  <TextField
                    id={option.id}
                    select
                    label={option.label}
                    helperText={option.helperText}
                    variant={option.variant}
                    value={
                      value.inputs.unitRooms[option.category][option.id]?.num
                        /*? value.inputs.unitRooms[option.category][option.id].num
                        : (option.is_required ? 1 : 0)*/
                    }
                    onChange={(e) => {
                      value.ChangeRooms(option, e.target.value)
                    }}
                  >
                    {[...(option.is_required ? [] : [0]),1, 2, 3, 4, 5, 6, 7].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </React.Fragment>
              ))}
            </div>
          </Box>
        </Item>
      </Grid>
    </Grid>
  );
}

export default UnitSpaces;
