import { Redirect, Route } from "react-router-dom";
import {  useSelector   } from 'react-redux'


export default function AuthProtectedRoute(props){
    
    let isLogged = useSelector((state) => state.auth.isLogged)
    
    if( props.type === "authed" ){
        if( isLogged ){
            return (
                <Route path={props.path}>
                    {props.children}
                </Route>
            )
        }else{
            return (
                <Route path={props.path}>
                    <Redirect to="/login" />
                </Route>
            )
        }    
    }else{
        if( isLogged ){
            return (
                <Route path={props.path}>
                    <Redirect to="/create-unit" />
                </Route>
            )
            
        }else{
            return (
                <Route path={props.path}>
                    {props.children}
                </Route>
            )
        } 
    }
    
}