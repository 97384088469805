import { Grid } from "@mui/material";
import Left from "./Left";
import Right from "./Right";

import "./Login.css"

export default function Login(){


    return(

        <Grid container className="login authContainer" sx={{alignItems: "flex-start"}}>

            <Grid item xs={12} md={4} className="left">
                <Left />
            </Grid>

            <Grid item xs={12} md={8} className="right">
                <Right />
            </Grid>
        
        </Grid>

    )

}