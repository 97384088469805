import { configureStore } from '@reduxjs/toolkit'
import  authReducer  from '../src/components/hooks/auth'



export default configureStore({
  reducer: {
    auth: authReducer
  },
})
