import Paper from '@mui/material/Paper';
import { Container, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useRef, useState } from 'react';
import { Alert, Button, Grid, TextField } from "@mui/material";
import axios from 'axios';

export default function ForgotPassword(){

    let [success,setSuccess] = useState(false)
    let [error,setError] = useState(false)
    let [emailError,setEmailError] = React.useState(false)

    let [emailTouched,setEmailTouched] = React.useState(false)

    let emailIn = useRef()
    let formIsValid = emailTouched && !emailError ? true : false;

    function emailChanged(event){

        setEmailTouched(true)

        let email = event.target.value
        
        if( email === '' ){
            setEmailError(true)
        }else{
    
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(email.match(mailformat))
            {
                setEmailError(false)
            }
            else
            {
                setEmailError(true)
            }
    
        }

    }

    function sendEmail(e){
        // post api takes the email
        e.preventDefault();
        
        let reqObj = {
            email: document.getElementById('email').value
        }
        axios.post('https://api.10sap.com/api/password/email',reqObj).then((response)=>{
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false)
            },1000)
        },
            (error) => {
                console.log('error', error);
                setError(true)
                setTimeout(() => {
                    setError(false)
                },1000)
            }
        )
        
    }

    return(
        <Container maxWidth="sm">
            <Paper className="PaperMain loaderContainer" elevation={3}> 
                <Box sx={{ width: "100%" }}>
                    <Typography textAlign="center" sx={{mt:"0px !important"}} className="mr" variant="h5" component="h5">
                        Enter Your Email
                    </Typography>
                    <form onSubmit={sendEmail}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                ref={emailIn}
                                onChange={emailChanged}
                                size="medium"
                                margin="normal"
                                fullWidth 
                                type="email"
                                label="Email"
                                id="email"
                                error={emailError}
                                helperText={emailError ? "Email Not Valid" : ""}
                                />
                            </Grid>
                            { success &&
                                <Grid mt="20px" textAlign="center" item xs={12} md={12}>
                                    <Alert severity="success">We Sent You Mail!</Alert>
                                </Grid> 
                            }
                            { error &&
                                <Grid mt="20px" textAlign="center" item xs={12} md={12}>
                                    <Alert severity="error">Email Not Found!</Alert>
                                </Grid> 
                            }
                            <Grid mt="20px" textAlign="center" item xs={12} md={12}>
                                <Button type="submit" size="large" disabled={!formIsValid} variant="contained">Send</Button>
                            </Grid>
                        </Grid>
                        
                        
                    </form>
                </Box>
            </Paper>
        </Container>
    )
}