import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../hooks/auth";
import "../../login/form/Form.css";
import { useRef } from "react";
import { getPrivacyPilcies } from "../../../api/api";

export default function Form() {
  let dispatch = useDispatch();

  let [loginError, setLoginError] = React.useState(false);

  let [emailError, setEmailError] = React.useState(false);
  let [passError, setPassError] = React.useState(false);
  let [nameError, setNameError] = React.useState(false);
  let [passConError, setPassConError] = React.useState(false);
  let [phoneError, setPhoneError] = React.useState(false);

  let [emailTouched, setEmailTouched] = React.useState(false);
  let [passTouched, setPassTouched] = React.useState(false);
  let [nameTouched, setNameTouched] = React.useState(false);
  let [passConTouched, setPassConTouched] = React.useState(false);
  let [phoneTouched, setPhoneTouched] = React.useState(false);


  const [acceptTerms, setAcceptTerms] = useState(false)
  let emailIn = useRef();
  let passwordIn = useRef();
  let passConIn = useRef();
  let nameIn = useRef();
  let phoneIn = useRef();

  function emailChanged(event) {
    setEmailTouched(true);

    let email = emailIn.current.children[1].children[0].value;

    if (email === "") {
      setEmailError(true);
    } else {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (email.match(mailformat)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }
  }

  function passChanged(event) {
    setPassTouched(true);
    let pass = passwordIn.current.children[1].children[0].value;

    if (pass === "") {
      setPassError(true);
    } else {
      setPassError(false);
    }
  }

  function nameChanged(event) {
    setNameTouched(true);
    let name = nameIn.current.children[1].children[0].value;

    if (name === "") {
      setNameError(true);
    } else {
      setNameError(false);
    }
  }

  function phoneChanged(event) {
    setPhoneTouched(true);
    let phone = phoneIn.current.children[1].children[0].value;
    if (phone === "") {
      setPhoneError(true);
    } else {
      if (phone.length !== 11) {
        setPhoneError(true);
      } else {
        setPhoneError(false);
      }
    }
  }

  function passConChanged(event) {
    let password = passwordIn.current.children[1].children[0].value;
    setPassConTouched(true);
    let passCon = passConIn.current.children[1].children[0].value;
    if (passCon === "") {
      setPassConError(true);
    } else {
      if (passCon !== password) {
        setPassConError(true);
      } else if (passCon === password) {
        setPassConError(false);
      }
    }
  }
  let formIsValid = emailTouched && !emailError && !passError && passTouched && !passConError && passConTouched && !nameError && nameTouched && !phoneError && phoneTouched ? true : false

  function registerHandler(event) {
    event.preventDefault();
    phoneChanged()
    nameChanged()
    emailChanged()
    passChanged()
    passConChanged()
    setLoginError(null)
    if(formIsValid && acceptTerms){
        let email = emailIn.current.children[1].children[0].value;
        let pass = passwordIn.current.children[1].children[0].value;
        let name = nameIn.current.children[1].children[0].value;
        let passCon = passConIn.current.children[1].children[0].value;
        let phone = phoneIn.current.children[1].children[0].value;
    
        let reqObj = {
          email: email,
          password: pass,
          password_confirmation: passCon,
          phone: phone,
          name: name,
        };
    
        axios
          .post("https://api.10sap.com/api/register", reqObj)
          .then((response) => {
            if (response.data.success) {
              localStorage.setItem("access_token", response.data.token);
              localStorage.setItem("userData", JSON.stringify(response.data.user));
              dispatch(authActions.loginHandler());
            } else {
              setLoginError(response.data.errors[0]);
            }
          });
    }else{
        if(!acceptTerms){
            setLoginError("Need to accept terms and conditions")
        }
    }
    
  }



  //////// terms

  const [termsOpen, setTermsOpen] = useState(false);
  const [terms, setTerms] = useState(null);

  useEffect(() => {
    getPrivacyPilcies().then((res) => {
      setTerms(res?.data?.terms_and_conditions);
    });
  }, []);

  return (
    <React.Fragment>
      <Grid container>
        <form onSubmit={registerHandler}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                onChange={emailChanged}
                size="small"
                margin="normal"
                fullWidth
                type="email"
                label="Email"
                ref={emailIn}
                error={emailError}
                helperText={emailError ? "Email Not Valid" : ""}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <TextField
                onChange={nameChanged}
                size="small"
                margin="normal"
                fullWidth
                ref={nameIn}
                type="text"
                label="Name"
                error={nameError}
                helperText={nameError ? "Name Is Required" : ""}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <TextField
                onChange={passChanged}
                size="small"
                margin="normal"
                fullWidth
                ref={passwordIn}
                type="password"
                label="Password"
                error={passError}
                helperText={passError ? "Field Is Required" : ""}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <TextField
                onChange={passConChanged}
                size="small"
                margin="normal"
                fullWidth
                ref={passConIn}
                type="password"
                label="Confirm Password"
                error={passConError}
                helperText={passConError ? "Field Is Required" : ""}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <TextField
                ref={phoneIn}
                onChange={phoneChanged}
                size="small"
                margin="normal"
                fullWidth
                type="number"
                label="Phone"
                error={phoneError}
                helperText={phoneError ? "Phone Not Valid" : ""}
              />
            </Grid>

            <Grid item xs={12} md={12}>
            <Checkbox style={{padding: "0px"}} onClick={() => setAcceptTerms(!acceptTerms)} checked={acceptTerms} size="small" />
              <label>
                Accept our
                <span
                  onClick={() => setTermsOpen(true)}
                  style={{ textDecoration: "underline", cursor: "pointer", marginLeft: "5px" }}
                >
                  Terms and Conditions
                </span>
              </label>
            </Grid>

            {loginError && (
              <Grid item xs={12} md={12}>
                <Alert severity="error">{loginError}</Alert>
              </Grid>
            )}

            <Grid item xs={12} md={12}>
              <div className="btnsCon">
                <Button
                  type="submit"
                  size="normal"
                  variant="contained"
                >
                  Sign up
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Modal
        open={termsOpen}
        onClose={() => setTermsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-body">
          <Typography mb={3} fontWeight={"bold"} variant="h4" component="h2">
            Terms and Conditions
          </Typography>
          <div
            style={{ overflow: "auto", height: "90%", paddingRight: "20px" }}
          >
            {terms}
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
