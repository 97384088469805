import { createSlice } from '@reduxjs/toolkit'

let initialState = {
    isLogged: localStorage.getItem('access_token') ? true : false,
    //inputs: localStorage.getItem('inputs') ? JSON.parse(localStorage.getItem('inputs')) : null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    loginHandler(state){
        state.isLogged = true
    },
    logoutHandler(state){
        localStorage.removeItem('access_token')
        localStorage.removeItem('userData')
        localStorage.removeItem('activePlanStep')
        localStorage.removeItem('inputs')
        localStorage.removeItem('activeMainStep')
        state.isLogged = false
    },
  },
})

export const authActions = authSlice.actions

export default authSlice.reducer
