import Paper from '@mui/material/Paper';
import { Container, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useRef } from 'react';
import { Alert, Button, Grid, TextField } from "@mui/material";
import { useLocation } from 'react-router-dom';
import axios from 'axios';

export default function ResetPassword(){

    let [success,setSuccess] = React.useState(false)
    let [error,setError] = React.useState(false)

    let [passError,setPassError] = React.useState(false)
    let [passConError,setPassConError] = React.useState(false)
    let [emailError,setEmailError] = React.useState(false)

    let [passTouched,setPassTouched] = React.useState(false)
    let [passConTouched,setPassConTouched] = React.useState(false)
    let [emailTouched,setEmailTouched] = React.useState(false)

    let emailIn = useRef()
    let passwordIn = useRef()
    let passConIn = useRef()

    
    function emailChanged(event){

        setEmailTouched(true)

        let email = event.target.value
        
        if( email === '' ){
            setEmailError(true)
        }else{
    
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(email.match(mailformat))
            {
                setEmailError(false)
            }
            else
            {
                setEmailError(true)
            }
    
        }

    }

    function passChanged(event){

        setPassTouched(true)
        let pass = event.target.value
        
        if( pass === '' ){
            setPassError(true)
        }else{
            setPassError(false)
        }

    }

    function passConChanged(event){
        let password = passwordIn.current.children[1].children[0].value
        setPassConTouched(true)
        let passCon = event.target.value
        if( passCon === '' ){
            setPassConError(true)
        }else{
            if( passCon !== password ){
                setPassConError(true)
            }
            else if( passCon === password ){
                setPassConError(false)
            }
        }

    }

    let formIsValid = emailTouched && !emailError && !passError && passTouched && !passConError && passConTouched ? true : false


    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();


    function handleReset(e){
        e.preventDefault();
        let reqObj = {
            email: document.getElementById('email').value,
            password: document.getElementById('password').value,
            password_confirmation: document.getElementById('passwordConfirm').value,
            token: query.get('token')
        }
        // post api takes token & newPassword
        axios.post('https://api.10sap.com/api/password/reset',reqObj).then(
            (response) => {
                setSuccess(true)
                setTimeout(() => {
                    setSuccess(false)
                },1000)
                
            }, 
            (error) => {
                console.log('error',error)
                setError(true)
                setTimeout(() => {
                    setError(false)
                },1000)
            }
        )
        
    }

    return(
        <Container maxWidth="sm">
            <Paper className="PaperMain loaderContainer" elevation={3}> 
                <Box sx={{ width: "100%" }}>
                    <Typography textAlign="center" sx={{mt:"0px !important"}} className="mr" variant="h5" component="h5">
                        Reset Your Password
                    </Typography>
                    <form onSubmit={handleReset}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                ref={emailIn}
                                onChange={emailChanged}
                                size="medium"
                                margin="normal"
                                fullWidth 
                                type="email"
                                label="Email"
                                id="email"
                                error={emailError}
                                helperText={emailError ? "Email Not Valid" : ""}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                onChange={passChanged}
                                size="medium"
                                margin="normal"
                                fullWidth 
                                ref={passwordIn}
                                type="password"
                                label="Password"
                                error={passError}
                                id="password"
                                helperText={passError ? "Field Is Required" : ""}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                onChange={passConChanged}
                                size="medium"
                                margin="normal"
                                fullWidth 
                                ref={passConIn}
                                type="password"
                                label="Confirm Password"
                                error={passConError}
                                id="passwordConfirm"
                                helperText={passConError ? "Field Is Required" : ""}
                                />
                            </Grid>
                            { success &&
                                <Grid mt="20px" textAlign="center" item xs={12} md={12}>
                                    <Alert severity="success">Your Password Has Been Reset Successfully!</Alert>
                                </Grid> 
                            }
                            { error &&
                                <Grid mt="20px" textAlign="center" item xs={12} md={12}>
                                    <Alert severity="error">Wrong Email!</Alert>
                                </Grid> 
                            }
                            <Grid mt="20px" textAlign="center" item xs={12} md={12}>
                                <Button type="submit" size="large" disabled={!formIsValid} variant="contained">Reset</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
        </Container>
    )
}