

import React from 'react';
import './Loader.css';

export default function Loader(props){

    return(
        <React.Fragment>
            
            <div className={props.loading ? "show" : ""} id="loader">
                <img src="/images/loader2.gif" alt="" />
            </div> 
            
        </React.Fragment>
        
    )
}