import React, { useContext, useState } from "react";
import { LabelContext } from "../hooks/InputFeild";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "100%",
  boxShadow: "none",
}));

function Details() {
  const value = useContext(LabelContext);
  const Details = value.inputs.details;
  const { t } = useTranslation();
  const [floorsError, setFloorsError] = useState(false);
  const [areaError, setAreaError] = useState(false);

  function validateFloors(e) {
    value.changeFloorsNum(e.target.value);
    if (e.target.value > 5) {
      setFloorsError(true);
    } else {
      setFloorsError(false);
    }
  }

  function validateArea(e) {
    value.handleChange("unitArea")(e)
    if (e.target.value < 80) {
      setAreaError(true);
    } else {
      setAreaError(false);
    }
  }

  return (
    <Box component="form" noValidate autoComplete="off">
      <Item>
        <Typography
          className="mr title-step"
          gutterBottom
          variant="h5"
          component="h5"
        >
          {t("UnitArea")}
        </Typography>

        <Grid container spacing={2}>
          <Grid item md={5} xs={12}>
            <img
              className="locationimg"
              src={process.env.PUBLIC_URL + "/images/calc1.gif"}
              alt="Location"
              loading="lazy"
            />
          </Grid>

          <Grid className="claclmeters" item md={7} xs={12}>
            <TextField
              className="wd100 mr"
              id="unit-area"
              required
              type="number"
              placeholder={t(
                "What is the total area of your unit in square meters according to contract?"
              )}
              error={areaError}
              helperText={areaError ? t("Must Be More Than 80 Sq.M") : ""}

              variant="standard"
              onChange={validateArea}
              defaultValue={Details.unitArea}
            />

            <TextField
              className="wd100 mr"
              id="number-of-floor"
              placeholder={t("How many levels are there within your unit? ")}
              required
              type="number"
              variant="standard"
              onChange={validateFloors}
              defaultValue={Details.manyFloor}
              error={floorsError}
              helperText={floorsError ? t("Must Be Less Than 6") : ""}
            />
            <Typography
              className="mr note-step"
              gutterBottom
              variant="p"
              component="p"
            >
              <span>Note:</span> for a standard apartment, the answer to this
              question is usually 1
            </Typography>
          </Grid>
        </Grid>
      </Item>
    </Box>
  );
}

export default Details;
