import { Typography } from "@mui/material";
import React from "react";
import Form from "./form/Form";
import { Link } from "react-router-dom";
import "./Right.css"
import SocialLogin from "./social-login/SocialLogin";

export default function Right(){

    return(
        
        <React.Fragment>
            <div className="container">

                <img src='/images/logo.png' alt="logo" className="logo" />

                <Typography variant="h6" className="title">
                    <span className="d-block">Welcome back,</span><br />
                    <span>Please sign in to your account.</span>
                </Typography>

                <SocialLogin />

                <Typography variant="p">
                    No account?
                    <Link to="/register" className="text-primary">Sign up now</Link>
                </Typography>
                
                <br />

                <Typography variant="p">
                    <Link to="/forgotpassword" className="text-primary">Forgot Your Password?</Link>
                </Typography>
                
                <Form />

            </div>
        </React.Fragment>

    )

}