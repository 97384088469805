import React, { useContext, useEffect } from "react";
import { LabelContext } from "../../hooks/InputFeild";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import PlanStep from "./PlanStep";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Paper from "@mui/material/Paper";
import { Container } from "@mui/material";
import { DATA } from "../../hooks/constant";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import "./PlansSteps.css"

export default function PlansSteps() {
  const value = useContext(LabelContext);
  const { t } = useTranslation();
  const [allSteps, setallSteps] = React.useState([
    { api: "design-fees", title: t("Design Fees"), step: "design" },
    {
      api: "architectural-modifications",
      title: t("Architectural Modifications"),
      step: "architectural",
    },
    {
      api: "electrical",
      title: t("Electrical & lighting systems"),
      step: "electrical",
    },
    {
      api: "plumbing",
      title: t("Plumbing & Sanitary works"),
      step: "plumbing",
    },

    { api: "bathtub", title: t("Shower Systems"), step: "bathtub" },

    {
      api: "ac",
      title: t("Ventilation works (Air conditioners)"),
      step: "ac",
    },
    { api: "plastring", title: t("Plaster Works"), step: "plastering" },
    {
      api: "gypsum-board",
      title: t("False Ceiling (Gypsum boards)"),
      step: "gypsum",
    },
    {
      api: "reception-flooring",
      title: t("Reception Flooring"),
      step: "reception_flooring",
    },

    {
      api: "rooms-flooring",
      title: t("Rooms and corridor Flooring"),
      step: "rooms_flooring",
    },
    //{ api: "skirting", title: t("Skirting"), step: "skirting" },

    {
      api: "bathrooms-and-kitchen",
      title: t("Bathrooms & Kitchen cladding tiles"),
      step: "bathrooms_and_kitchen",
    },
    { api: "paints", title: t("Paint works"), step: "paints" },

    {
      api: "doors-interiors",
      title: t("Internal Doors selections"),
      step: "doorsInterior",
    },
    {
      api: "doors-main-door",
      title: t("External door selections"),
      step: "mainDoor",
    },
  ]);
  const FloorNum = value.inputs.details.manyFloor;
  useEffect(() => {
    if (FloorNum > 1) {
      setallSteps([
        ...allSteps,
        { api: "stairs", title: t("Stair & Handrail"), step: "stairs" },
      ]);
    } else {
    }
  }, []);

  function getStepContent(step) {
    return (
      <PlanStep
        api={allSteps[step].api}
        title={allSteps[step].title}
        step={allSteps[step].step}
      />
    );
  }

  const lang = i18next.language;

  ///////
  const [activeStep, setActiveStep] = React.useState(
    localStorage.getItem("activePlanStep")
      ? parseInt(localStorage.getItem("activePlanStep"))
      : 0
  );

  const handleNext = () => {
    const newActive = activeStep + 1;
    localStorage.setItem("activePlanStep", newActive);
    setActiveStep(newActive);
    window.scrollTo(0,0)
  };

  const handleBack = () => {
    const newActive = activeStep - 1;
    localStorage.setItem("activePlanStep", newActive);
    setActiveStep(newActive);
    window.scrollTo(0,0)
  };

  const disablebtn = () => {
    return allSteps[activeStep].step === "plastering"
      ? false
      : Object.keys(value.inputs.choosenPlans[allSteps[activeStep].step])
          .length === 0;
  };

  let history = useHistory();

  function finishSteps() {
    let head = "Bearer " + localStorage.getItem("access_token");
    let headers = { headers: { Authorization: head } };
    var today = new Date();
    var date =
      today.getFullYear() +
      " / " +
      (today.getMonth() + 1) +
      " / " +
      today.getDate();
      let reqObj = {
        data: {
          ...value.inputs,
          UnitType: value.inputs.selectedUnitType.id,
          date,
        },
      };
      axios
      .post("https://api.10sap.com/api/userUnit/store", reqObj, headers)
      .then((response) => {
        const url = "/invoice/" + response.data.UserUnit.id;
        history.push(lang === "en" ? url : "/ar" + url);

        localStorage.setItem("inputs", JSON.stringify(DATA));
        localStorage.setItem("activeMainStep", 0);
        localStorage.removeItem("activePlanStep");
      });
  }

  useEffect(() => {
    if (!value.inputs.details.unitArea) {
      history.push(lang === "en" ? "/create-unit" : "/ar/create-unit");
    }
  }, []);

  return (
    <Container maxWidth="lg" className="plansStepsContainer">
      <Paper className="PaperMain loaderContainer" elevation={3}>
        <Box sx={{ width: "100%" }}>
          <h5 className="stepOffset" style={{ textAlign: "center", fontWeight: "bold" }}>
            {activeStep + 1} / {allSteps.length}
          </h5>

          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }} component="div">
              {getStepContent(activeStep)}
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                variant="contained"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {t("Back")}
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />

              {activeStep === allSteps.length - 1 ? (
                <Button
                  variant="contained"
                  disabled={disablebtn()}
                  onClick={finishSteps}
                >
                  {t("Finish")}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled={disablebtn()}
                  onClick={handleNext}
                >
                  {t("Next")}
                </Button>
              )}
            </Box>
          </React.Fragment>
        </Box>
      </Paper>
    </Container>
  );
}
