import { Alert, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, TextField } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import "./Profile.css"
import Paper from '@mui/material/Paper';
import { Container } from "@mui/material";
import Loader from '../loader/Loader'
import { useTranslation } from "react-i18next";


import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import i18next from "i18next";
import DeleteModal from "./DeleteModal";


const theme = createTheme({
    direction: 'rtl', // Both here and <body dir="rtl">
  });

    // Create rtl cache
    const cacheRtl = createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, rtlPlugin],
    });


export default function Profile(){

    const {t} = useTranslation()

    let [loading,setLoading] = React.useState(true);

    let [success,setSuccess] = useState(false)
    let [isEditing,setIsEditing] = useState(false)

    let [nameError,setNameError] = React.useState(false)
    let [phoneError,setPhoneError] = React.useState(false)

    let [nameTouched,setNameTouched] = React.useState(true)
    let [phoneTouched,setPhoneTouched] = React.useState(true)

    let nameIn = useRef()
    let phoneIn = useRef()

    const [user, setUser] = React.useState('');
    const [name, setName] = React.useState('');
    const [phone, setPhone] = React.useState('');

    function nameChanged(event){
        setName(event.target.value);
        setNameTouched(true)
        let name = event.target.value
        
        if( name === '' ){
            setNameError(true)
        }else{
            setNameError(false)
        }

    }
    
    const phoneChanged = (event) => {
        setPhone(event.target.value);
        setPhoneTouched(true)
        let phone = event.target.value
        
        if( phone === '' ){
            setPhoneError(true)
        }else{
            setPhoneError(false)
        }
    };

    let formIsValid = !phoneError && phoneTouched && !nameError && nameTouched ? true : false;

    // user
    useEffect(() => {

        let head = "Bearer " + localStorage.getItem('access_token')

        let headers = {headers: {Authorization: head}}

        axios.get('https://api.10sap.com/api/me',headers).then(
            (response) => {
                setLoading(false)
                setName(response.data.user.name)
                setPhone(response.data.user.phone)
                setUser(response.data.user)
                if( !response.data.user.phone ){
                    setPhoneError(true)
                }
                var personIcon = document.getElementById("personIcon")
                if( personIcon ){
                    personIcon.classList.add('show')
                }
            }
        )
    
    },[]);

    function handleEdit(){
        setIsEditing(true)
    }

    function handleBack(){
        setIsEditing(false)
        setName(user.name)
        setPhone(user.phone)
        setNameError(false)
    }

    function handleUpdate(){
        setLoading(true)
        var personIcon = document.getElementById("personIcon")
                if( personIcon ){
                    personIcon.classList.remove('show')
                }
        let head = "Bearer " + localStorage.getItem('access_token')

        let headers = {headers: {Authorization: head}}

        let reqObj = {
            phone: phone,
            name: name
        }

        axios.post('https://api.10sap.com/api/profile/edit',reqObj,headers).then(
            (response) => {
                setLoading(false)
                localStorage.setItem('userData',JSON.stringify(response.data.user))
                setUser(response.data.user)
                setIsEditing(false)
                setSuccess(true)
                setTimeout(() => {
                    setSuccess(false)
                },1000)
                if( personIcon ){
                    personIcon.classList.add('show')
                }
            }
        )
        
        
    }

    ////////////////// delete


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  


    return (
        <Container maxWidth="sm">
      <Paper className="PaperMain loaderContainer" elevation={3}> 
            <Box sx={{ flexGrow: 1 }}>
                
                <PersonIcon id="personIcon" />

                <Typography sx={{marginTop: '0px !important', marginBottom: "0px !important"}} textAlign= "center" className="mr" variant="h4" component="h4">
                    {t("My Profile")}
                </Typography>
                {i18next.language === 'ar' ? <CacheProvider value={cacheRtl}>
                    <ThemeProvider theme={theme}>
                        <div dir={ i18next.language === 'ar' ? 'rtl' : "ltr"}>
                            <Grid item xs={12} sm={12} md={12}>
                    
                                <TextField
                                disabled={!isEditing}
                                ref={nameIn}
                                size="medium"
                                margin="normal"
                                fullWidth 
                                type="text"
                                label={t("name")}
                                error={nameError}
                                onChange={nameChanged}
                                value={name}
                                helperText={nameError ? t("Field Is Required") : ""}
                                />
                                
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} mb="20px" >
                                <TextField
                                disabled={!isEditing}
                                size="medium"
                                margin="normal"
                                fullWidth 
                                ref={phoneIn}
                                type="text"
                                label={t("phone")}
                                value={phone}
                                onChange={phoneChanged}
                                error={phoneError && isEditing}
                                helperText={phoneError && isEditing ? t("Field Is Required") : ""}
                                />
                            </Grid>
                        </div>
                    </ThemeProvider>
                </CacheProvider> : 
                <div>
                <Grid item xs={12} sm={12} md={12}>        
                    <TextField
                    disabled={!isEditing}
                    ref={nameIn}
                    size="medium"
                    margin="normal"
                    fullWidth 
                    type="text"
                    label={t("name")}
                    error={nameError}
                    onChange={nameChanged}
                    value={name}
                    helperText={nameError ? t("Field Is Required") : ""}
                    />
                    
                </Grid>
                <Grid item xs={12} sm={12} md={12} mb="20px" >
                    <TextField
                    disabled={!isEditing}
                    size="medium"
                    margin="normal"
                    fullWidth 
                    ref={phoneIn}
                    type="text"
                    label={t("phone")}
                    value={phone}
                    onChange={phoneChanged}
                    error={phoneError && isEditing}
                    helperText={phoneError && isEditing ? t("Field Is Required") : ""}
                    />
                </Grid>
            </div>
                }
                {
                    success &&
                    <Grid item xs={12} sm={12} md={12} mb="20px">
                        <Alert severity="success">{t("Profile Updated Successfully!")}</Alert>
                    </Grid>
                }
                

                {
                    !isEditing &&
                    <Grid item xs={12} md={12} sx={{display: "flex", justifyContent:"center"}}>
                        <Button onClick={handleEdit} style={{marginRight: i18next.language === 'ar' ? "0px" : '15px', marginLeft: i18next.language === 'ar' ? "15px" : "0px"}} type="submit" size="normal" variant="contained">{t("Edit")}</Button>
                        <Button onClick={handleOpen} className="danger" type="button" size="normal" variant="contained">{t("Delete Account")}</Button>

                    </Grid>
                }
                {
                    isEditing &&
                    <Grid item xs={12} md={12}>
                        <Grid sx={{margin: "auto", width: 'fit-content'}}>
                            <Button onClick={handleUpdate} sx={{marginRight: "10px"}} disabled={!formIsValid} type="submit" size="normal" variant="contained">{t("Update")}</Button>
                            <Button onClick={handleBack} type="submit" size="normal" variant="outlined">{t("Back")}</Button>
                        </Grid>
                    </Grid>
                }
                
            </Box>
            
            <Loader loading={loading} />

            <DeleteModal 
                open={open}
                handleClose={handleClose}
            />
                
      </Paper>
    </Container>
    )

}