import React, { useContext, useEffect, useState } from "react";

import { LabelContext } from "../hooks/InputFeild";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getUnitTypes } from "../../api/api";
import { useTranslation } from "react-i18next";
function UnitType() {
  const value = useContext(LabelContext);
  const { t } = useTranslation();
  const [UnitTypes, setUnitTypes] = useState([]);

  useEffect(() => {
    getUnitTypes().then(
      (res) => {
        setUnitTypes(res.data.UnitType);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "100%",
    height: "100%",
    boxShadow: "0px 0px 0px 0px #e5e0e0",
  }));

  return (
    <Box className="InputRedio" sx={{ flexGrow: 1 }}>
      <Item>
        <Typography
          className="mr title-step"
          gutterBottom
          variant="h5"
          component="h5"
        >
          {t("IdentifyUnit")}
        </Typography>

        <Grid container spacing={4}>
          <ul>
            {UnitTypes.map((unit) => (
              <li key={unit.id}>
                <input
                  type="radio"
                  name="UnitType"
                  value={unit.id}
                  id={unit.id}
                  checked={unit.id == value.inputs.selectedUnitType}
                  onChange={() => {
                    value.ChangeunitType(unit);
                  }}
                />
                <label htmlFor={unit.id} className="target">
                  <img src={unit.icon} alt={unit.value} />
                </label>
                <p>{unit.value}</p>
              </li>
            ))}
          </ul>
        </Grid>
      </Item>
    </Box>
  );
}

export default UnitType;
