import "./invoice.css";
import React, { useContext, useEffect, useState } from "react";
import { LabelContext } from "../../hooks/InputFeild";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Loader from "../../loader/Loader";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Box,
  Checkbox,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next, { t } from "i18next";
import { Close } from "@mui/icons-material";
function calcRooms(rooms) {
  let count = 0;
  Object.values(rooms).map((r) => {
    Object.values(r).map((a) => (count += a.num));
  });

  return count;
}

export default function FinalInvoice() {
  const value = useContext(LabelContext);
  const { t } = useTranslation();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let [loading, setLoading] = useState(true);

  let [invoiceData, setInvoiceData] = useState({});
  const [roomsData, setRoomsData] = useState([]);
  const [uponRequest, setUponRequest] = useState([]);
  let params = useParams();

  useEffect(() => {
    let head = "Bearer " + localStorage.getItem("access_token");
    let headers = {
      headers: { Authorization: head, "Accept-language": i18next.language },
    };

    axios.get("https://api.10sap.com/api/userUnit/" + params.id, headers).then(
      (response) => {
        const data = response.data.UserUnit.data;
        setInvoiceData({ ...data, rooms: calcRooms(data.unitRooms) });
        setLoading(false);

        setUponRequest(response.data.upon_request);

        setRoomsData(
          Object.values(data.unitRooms)
            .map((r) => Object.values(r).map((a) => a))
            .flat(2)
        );
      },
      (error) => {
        setInvoiceData([]);
        setLoading(false);
      }
    );
  }, []);

  const download = () => {
    var downloadSection = document.getElementById("download_section");
    var cWidth = downloadSection.offsetWidth;
    var cHeight = downloadSection.offsetHeight;

    var topLeftMargin = 40;
    var pdfWidth = cWidth + topLeftMargin * 2;
    var pdfHeight = pdfWidth * 1.5 + topLeftMargin * 2;
    var canvasImageWidth = cWidth;
    var canvasImageHeight = cHeight;
    var totalPDFPages = Math.ceil(cHeight / pdfHeight) - 1;

    html2canvas(downloadSection, { allowTaint: true }).then(function (canvas) {
      canvas.getContext("2d");
      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      var pdf = new jsPDF("p", "pt", [pdfWidth, pdfHeight]);
      pdf.addImage(
        imgData,
        "JPG",
        topLeftMargin,
        topLeftMargin,
        canvasImageWidth,
        canvasImageHeight
      );
      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(pdfWidth, pdfHeight);
        pdf.addImage(
          imgData,
          "JPG",
          topLeftMargin,
          -(pdfHeight * i) + topLeftMargin * 0,
          canvasImageWidth,
          canvasImageHeight
        );
      }
      pdf.save("10sap-invoice.pdf");
    });
  };

  let titles = {
    design: t("Design Fees"),
    architectural: t("Architectural Modifications"),
    electrical: t("Electrical & lighting systems"),
    plumbing: t("Plumbing & Sanitary works"),
    bathtub: t("Shower systems"),
    ac: t("Ventilation works (Air conditioners)"),
    plastering: t("Plaster Works"),
    gypsum: t("False Ceiling (Gypsum boards)"),
    reception_flooring: t("Reception Flooring"),
    rooms_flooring: t("Rooms and corridor Flooring"),
    //skirting: t("Skirting"),
    bathrooms_and_kitchen: t("Bathrooms & Kitchen cladding tiles"),
    stairs: t("Stair & Handrail"),
    paints: t("Paint works"),
    doorsInterior: t("Internal Doors selections"),
    mainDoor: t("External door selections"),
  };

  const [valueModal, setValueModal] = useState(false);
  return (
    <React.Fragment>
      <div className="cs-container">
        <div className="cs-invoice cs-style1">
          {loading && (
            <Grid sx={{ paddingTop: "35px", paddingBottom: "35px" }}>
              <Loader loading={loading} />
            </Grid>
          )}

          {!loading && Object.keys(invoiceData).length > 0 && (
            <React.Fragment>
              <div className="cs-invoice_in" id="download_section">
                <div className="cs-invoice_head cs-type1 cs-mb25">
                  <div className="cs-invoice_right cs-text_right">
                    <div className="cs-logo cs-mb5">
                      <img id="logo" src="/images/logo.png" alt="Logo" />
                    </div>
                  </div>
                  <div className="cs-invoice_left">
                    <p className="invoiceTitle">{t("Invoice")}</p>
                    <p className="cs-invoice_number cs-primary_color cs-mb5 cs-f16">
                      <b className="cs-primary_color">{t("Invoice No")}:</b>{" "}
                      #SM75692
                    </p>
                  </div>
                </div>

                <div className="cs-invoice_head cs-mb10 cs-type1 invoiceData">
                  <div className="cs-invoice_left">
                    <b className="cs-primary_color">{t("User Name")}: </b>
                    <span>{userData.name}</span>
                  </div>
                  <div className="cs-invoice_right cs-text_right">
                    <b className="cs-primary_color">{t("Date")} : </b>
                    <span id="date">{invoiceData.date}</span>
                  </div>
                </div>

                <div className="cs-invoice_head cs-mb10 unitData">
                  <div className="cs-invoice_left">
                    <b className="cs-primary_color">{t("Unit Address")}: </b>
                    <span>{invoiceData.area_name}</span>
                  </div>
                  <div className="cs-invoice_left">
                    <b className="cs-primary_color">{t("Unit Type")} : </b>
                    {invoiceData.selectedUnitTypeName}
                  </div>
                  <div className="cs-invoice_left">
                    <b className="cs-primary_color">{t("Unit Area")} : </b>
                    <span>{invoiceData.details.unitArea}</span>
                  </div>
                  <div className="cs-invoice_left">
                    <b className="cs-primary_color">{t("Unit Rooms")}: </b>
                    <span>{invoiceData.rooms}</span>
                  </div>
                </div>

                <div
                  style={{ marginBottom: "20px" }}
                  className="cs-table cs-style1 invoiceTable"
                >
                  <div className="cs-round_border">
                    <div className="cs-table_responsive">
                      <table>
                        <thead>
                          <tr>
                            <th className="cs-width_6 cs-semi_bold cs-primary_color cs-focus_bg">
                              {t("Room")}
                            </th>

                            <th
                              style={{ textAlign: "center" }}
                              className="cs-width_6 cs-text_center cs-semi_bold cs-primary_color cs-focus_bg"
                            >
                              {t("Count")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {roomsData.map((room, i) => {
                            return (
                              <tr key={i}>
                                <td className="cs-width_6 bold-text">
                                  {room.roomObj.label}
                                </td>
                                <td className="cs-width_6 cs-text_center bold-text">
                                  {room.num}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="cs-table cs-style1 invoiceTable">
                  <div className="cs-round_border">
                    <div className="cs-table_responsive">
                      <table>
                        <thead>
                          <tr>
                            <th className="cs-width_4 cs-semi_bold cs-primary_color cs-focus_bg">
                              {t("Description")}
                            </th>
                            <th
                              style={{ textAlign: "center" }}
                              className="cs-width_4 cs-text_center cs-semi_bold cs-primary_color cs-focus_bg"
                            >
                              {t("Plan")}
                            </th>
                            <th
                              style={{ textAlign: "center" }}
                              className="cs-width_4 cs-text_center cs-semi_bold cs-primary_color cs-focus_bg"
                            >
                              {t("Price")}
                            </th>
                            <th
                              style={{ textAlign: "center" }}
                              className="cs-width_4 cs-text_center cs-semi_bold cs-primary_color cs-focus_bg"
                            >
                              {t("Percentage")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(titles).map((field) => {
                            if (
                              !(
                                invoiceData.details.manyFloor === "1" &&
                                field === "stairs"
                              )
                            ) {
                              return (
                                <tr key={field}>
                                  <td className="cs-width_4 bold-text">
                                    {titles[field]}
                                  </td>
                                  <td className="cs-width_4 cs-text_center">
                                    {(field != "plastering" ||
                                      (field === "plastering" &&
                                        invoiceData.plasteringChoice)) &&
                                      invoiceData.choosenPlans[field][
                                        "package_name_" + i18next.language
                                      ]}
                                    {field === "plastering" &&
                                      !invoiceData.plasteringChoice && <>_</>}
                                  </td>
                                  <td className="cs-width_4 cs-text_center bold-text">
                                    {(field != "plastering" ||
                                      (field === "plastering" &&
                                        invoiceData.plasteringChoice)) &&
                                      Math.round(
                                        value.calculate(
                                          field,
                                          invoiceData.choosenPlans[field],
                                          invoiceData.details.unitArea,
                                          invoiceData.unitRooms,
                                          invoiceData.details.manyFloor,
                                          invoiceData.selectedUnitTypeFactor,
                                          invoiceData.area_factor,
                                          invoiceData.airConditioners,
                                          invoiceData.airConditionersWithReception
                                        )
                                      ).toLocaleString("en-US", {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      })}{" "}
                                    {t("EGP")}
                                    {field === "plastering" &&
                                      !invoiceData.plasteringChoice && <>0</>}
                                  </td>
                                  <td className="invoice-percentage">
                                    {(field != "plastering" ||
                                      (field === "plastering" &&
                                        invoiceData.plasteringChoice)) &&
                                      Math.round(
                                        (value.calculate(
                                          field,
                                          invoiceData.choosenPlans[field],
                                          invoiceData.details.unitArea,
                                          invoiceData.unitRooms,
                                          invoiceData.details.manyFloor,
                                          invoiceData.selectedUnitTypeFactor,
                                          invoiceData.area_factor,
                                          invoiceData.airConditioners,
                                          invoiceData.airConditionersWithReception
                                        ) /
                                          invoiceData.totalPrice) *
                                          100
                                      ) + " %"}

                                    {field === "plastering" &&
                                      !invoiceData.plasteringChoice && <>0 %</>}
                                  </td>
                                </tr>
                              );
                            }
                          })}

                          {uponRequest.map((plan) => (
                            <tr key={plan}>
                              <td className="cs-width_6 bold-text">{plan}</td>
                              <td className="cs-width_6 cs-text_center">
                                Upon request
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="cs-invoice_footer">
                    <table>
                      <tbody>
                        <tr className="cs-border_none cs-focus_bg">
                          <td className="cs-width_4 cs-text_center cs-border_top_0 cs-bold cs-f16 cs-primary_color">
                            {t("Total Price")}
                          </td>
                          <td className="cs-width_4 cs-text_center cs-border_top_0 cs-bold cs-f16 cs-primary_color">
                            {Math.round(invoiceData.totalPrice).toLocaleString(
                              "en-US",
                              {
                                maximumFractionDigits: 2,
                              }
                            )}{" "}
                            {t("EGP")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <p style={{ marginTop: "5px" }}>
                  The prices listed are all-inclusive of all applicable taxes
                  according to Egyptian taxation laws.
                </p>

                <div className="cs-note">
                  <div className="cs-note_right">
                    <p className="cs-mb0">
                      <b className="cs-primary_color cs-bold">
                        Confirmation Fee for Site Visit: 1000 EGP (Five Hundred
                        Egyptian pounds only)
                      </b>
                    </p>
                    <p className="cs-m0">
                      Client's approval of the priced items and confirmation of
                      services is required before we proceed with the site
                      visit. This approval can be confirmed through a recorded
                      phone call or email. Once your approval is received and
                      confirmed, a non-refundable confirmation fee is required.
                      You can make this payment securely online through our
                      payment portal. Once the payment is received, our agent
                      will schedule a site visit at your convenience.
                    </p>
                  </div>
                </div>

                <div className="cs-note">
                  <div className="cs-note_right">
                    <p className="cs-mb0">
                      <b className="cs-primary_color cs-bold">{t("Note")}:</b>
                    </p>
                    <p className="cs-m0">
                      1. After agreement on the material categories, we kindly
                      ask you to visit our office for the final material
                      approval. This ensures that we provide you with the best
                      possible products for your dream home
                      <br />
                      2. Please note that our website provides a precise
                      estimate of the cost of your project. However, to provide
                      the final approval on prices, we will need to conduct a
                      site visit to ensure that everything is in order.
                      <br />
                      3. Please note that a detailed breakdown of prices and
                      taxes will be provided in a separate, detailed invoice,
                      which will be attached to the finalized contract after
                      your approval.
                    </p>
                  </div>
                </div>
              </div>

            
              <a
                  id="download_btn"
                  download
                  href={
                    "https://api.10sap.com/api/userUnit/" +
                    params.id +
                    "/invoice?Authorization=Bearer " +
                    localStorage.getItem("access_token")
                  }
                  className="cs-invoice_btn invoiceBtn"
                  style={{marginTop: "15px", marginBottom: "10px"}}
                >
                  <span>{t("Download")}</span>
                </a>

                <a
                  id="download_btn"
                  onClick={() => setValueModal(true)}
                  className="cs-invoice_btn invoiceBtn"
                  style={{marginBottom: "10px"}}
                >
                  <span>
                    {t("Try")}

                    <img
                      src="/images/valu.webp"
                      style={{
                        width: "60px",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    />
                    {t("installments")}
                  </span>
                </a>
              <Link
                className="cs-invoice_btn invoiceBtn bg-primary"
                to={
                  (i18next.language === "ar" ? "/ar" : "") +
                  "/make-visit/" +
                  params.id
                }
                style={{color: "white", backgroundColor: "#fcd10d", border: "0px"}}
              >
                {t("Apply for Site Visit")}
              </Link>
            </React.Fragment>
          )}
          {!loading && Object.keys(invoiceData).length === 0 && (
            <Typography
              textAlign="center"
              className="mr"
              gutterBottom
              variant="h5"
              component="h5"
            >
              {t("Invoice Not Found")}
            </Typography>
          )}
        </div>
      </div>
      <ValuModal
        isOpen={valueModal}
        handleClose={() => setValueModal(false)}
        totalPrice={invoiceData.totalPrice}
      />
    </React.Fragment>
  );
}

function ValuModal({ isOpen, handleClose, totalPrice }) {
  const numOfMonthsOptions = [1, 2, 3, 4, 5, 6].concat(
    Array(18)
      .fill(0)
      .map((v, i) => 9 + i * 3)
  );

  const [data, setData] = useState({
    downPayment: 0,
    numOfMonths: null,
  });

  function calculatePMT(rate, nper, pv) {
    if (rate === 0) {
      return -(pv / nper); // If rate is 0, just return PV divided by number of periods
    }

    var pmt = (rate * pv) / (1 - Math.pow(1 + rate, -1 * nper));
    return -pmt; // PMT is usually a negative value since it's an outflow
  }

  let loan_amount = totalPrice - (data.downPayment || 0);
  let annual_rate = 0.34;
  let months = data.numOfMonths;

  let monthly_rate = annual_rate / 12;

  let monthly_installment =
    calculatePMT(monthly_rate, months, loan_amount) * -1;

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        style={{ height: "auto", position: "relative" }}
        className="modal-body"
      >
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <Close
            onClick={handleClose}
            fontSize="large"
            sx={{
              position: "absolute",
              cursor: "pointer",
              top: "20px",
              right: "20px",
            }}
          />
          <img
            src="/images/valu.webp"
            style={{ width: "160px", marginLeft: "10px" }}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography mb={0} fontSize={22} fontWeight={"bold"} variant="h4">
            Total Price
          </Typography>
          <Typography mb={0} fontWeight={"bold"} fontSize={22} variant="h4">
            {Math.round(totalPrice).toLocaleString("en-US", {
              maximumFractionDigits: 2,
            })}{" "}
            {t("EGP")}
          </Typography>
        </div>
        <TextField
          label="Down Payment"
          style={{ width: "100%", marginTop: "25px" }}
          value={data.downPayment}
          onChange={(v) =>
            setData((d) => ({ ...d, downPayment: v.target.value }))
          }
        />
        <div style={{ marginTop: "20px" }}>
          <label
            style={{
              marginBottom: "10px",
              display: "block",
              fontWeight: "bold",
            }}
          >
            Number of Months
          </label>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              rowGap: "20px",
              height: "30vh",
              overflow: "auto",
              paddingRight: "20px",
              paddingBottom: "5px",
            }}
          >
            {numOfMonthsOptions.map((v) => (
              <div
                onClick={() => setData((d) => ({ ...d, numOfMonths: v }))}
                style={{
                  width: "calc((100% / 6) - 10px)",
                  fontWeight: "bold",
                  padding: "15px",
                  borderRadius: "6px",
                  border: "1px solid black",
                  cursor: "progress",
                }}
                key={v}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {v}
                  <Checkbox
                    checked={v == data.numOfMonths}
                    sx={{ padding: "0px", marginTop: "-5px" }}
                  />
                </div>
                Months
              </div>
            ))}
          </div>
        </div>

        {data.numOfMonths && (
          <>
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                justifyContent: "space-between",
              }}
            >
              <Typography mb={0} fontSize={22} fontWeight={"bold"} variant="h4">
                Per month
              </Typography>
              <Typography mb={0} fontWeight={"bold"} fontSize={22} variant="h4">
                {Math.round(monthly_installment).toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}{" "}
                {t("EGP")}
              </Typography>
            </div>
            <p style={{ marginTop: "10px", marginBottom: "0px" }}>
              The calculated installment is only an estimate based on the
              provided interest rate and loan terms. Please note that the actual
              monthly payment may vary and could be adjusted after a thorough
              review and final valuation by ValU
            </p>
          </>
        )}
      </Box>
    </Modal>
  );
}
