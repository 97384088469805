import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Paper from "@mui/material/Paper";
import { Alert, Container, FormGroup, Grid, TextField } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import "../profile/Profile.css";
import { makeVisit } from "../../api/api";
import Loader from "../loader/Loader";
import { useParams } from "react-router-dom";
import i18next from "i18next";
import { GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete from "react-places-autocomplete";
import { useTranslation } from "react-i18next";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { LabelContext } from "../hooks/InputFeild";
import { DATA } from "../hooks/constant";

const theme = createTheme({
    direction: 'rtl', // Both here and <body dir="rtl">
  });

    // Create rtl cache
    const cacheRtl = createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, rtlPlugin],
    });


export const LocationSearchInput = (props) => {
  const { t } = useTranslation();
  const [placeService, setPlaceService] = useState(undefined);
  useEffect(() => {
    setPlaceService(
      new window.google.maps.places.PlacesService(document.createElement("div"))
    );
  }, []);

  const handleSelect = (address, placeId) => {
    const request = {
      placeId: placeId,
      fields: ["name", "geometry"],
    };

    placeService.getDetails(request, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        props.setLocation({
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
          address,
          city: place.name,
        });
      }
    });
  };

  return (
    <PlacesAutocomplete
      value={props.location.address}
      onChange={(v) => {
        props.setLocation({
          ...props.location,
          address: v,
        });
      }}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="autocomplete-container">
          <input
            {...getInputProps({
              placeholder: t("Search Places ..."),
              className: "location-search-input",
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    // className,
                    // style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

/*
const MapLocation = (props) => {
  //const UnitLocation = value.inputs.UnitLocation;

  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
    address: '',
    city: '',
  })

  const [places, setPlaces] = useState([]);

  const mapRef = useRef();

  const showPlace = (place) => {
    setPlaces((oldPlaces) => [...oldPlaces, place]);
    mapRef.current.map.setCenter(place.geometry.location);
  };

  return (
    
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <LocationSearchInput onPlaceChanged={showPlace} location={location} setLocation={setLocation} />
        </Grid>
        <Grid className="noMobile" item md={6} xs={12}>
          <Grid container sx={{ my: 3 }}>
            <div className="Map-Container">
              <Map
                ref={mapRef}
                google={props.google}
                className={"map"}
                zoom={18}
                initialCenter={props.center}
              >
                {places.map((place, i) => {
                  return (
                    <Marker
                      key={i}
                      position={{
                        lng: 30.802498,
                        lat: 26.820553,
                      }}
                    />
                  );
                })}
              </Map>
            </div>
            <Grid item md={6} xs={12}>
              <TextField
                id="Latitude"
                label="Latitude"
                variant="outlined"
                name="latitude"
                onChange={(e) => {setLocation({...location, latitude: e.target.value})}}
                value={location.latitude}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="longitude"
                label="longitude"
                variant="outlined"
                name="longitude"
                onChange={(e) => {setLocation({...location, longitude: e.target.value})}}
                value={location.longitude}
              />
            </Grid>
          </Grid>
              </Grid>
      </Grid>
 
  );
};*/

export function MakeVisit() {
  const { id } = useParams();
  const router = useHistory();
  const { t } = useTranslation();
  let [loading, setLoading] = useState(false);

  let [success, setSuccess] = useState(false);
  let [error, setError] = useState(null);

  let [phone, setPhone] = useState("");

  let [timeToCall, setTimeToCall] = useState("");

  let [timeToVisit, setTimeToVisit] = useState("");

  let [file, setFile] = useState(null);

  const createUnitUrl =
    (i18next.language === "ar" ? "/" + i18next.language : "") + "/create-unit";

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    const data = new FormData();
    data.append("unit_plan", file);
    data.append("time_for_call", timeToCall.replace("T", " ") + ":11");
    data.append("time_for_visit", timeToVisit.replace("T", " ") + ":11");
    data.append("location", address);
    data.append("map_location", JSON.stringify(location));
    data.append("phone", phone);
    data.append("unitId", id);
    data.append("user_id", JSON.parse(localStorage.getItem("userData")).id);

    makeVisit(data)
      .then((response) => {
        setLoading(false);
        setSuccess(true);
        setPhone("");
        setLocation("");
        setTimeToCall("");
        setTimeToVisit("");
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.message);

        setTimeout(() => {
          setError(null);
        }, 1000);
      });
  }

  function handleResetForm() {
    value.updateInputs(DATA);

    localStorage.removeItem("activePlanStep");
    localStorage.removeItem("inputs");
    localStorage.removeItem("activeMainStep");
    router.push(createUnitUrl);
    document.location.reload();
  }

  //// location

  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
    address: "",
    city: "",
  });

  const [address, setAddress] = useState("")

  const value = useContext(LabelContext);



  return (
    <Container maxWidth="sm">
      <Paper className="PaperMain loaderContainer" elevation={3}>
        <Box sx={{ width: "100%" }}>
          <PersonIcon id="personIcon" className="show" />

          <Box style={{ textAlign: "center" }}>
            <h4> {t("Site Visit Details")}</h4>
          </Box>
          <form onSubmit={handleSubmit}>
          {i18next.language === 'ar' ? 
          <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
              <div dir={ i18next.language === 'ar' ? 'rtl' : "ltr"}>
              <FormGroup sx={{ marginBottom: "20px" }}>
              <TextField
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                label={t("phone")}
                variant="outlined"
              />
            </FormGroup>
            <FormGroup sx={{ marginBottom: "20px" }}>
              <TextField
                value={timeToCall}
                onChange={(e) => {
                  if(new Date(e.target.value) >= new Date()){
                    setTimeToCall(e.target.value)
                  }
                }}
                placeholder="s"
                type="datetime-local"
                label={t("Suitable Time for Call")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormGroup>
            <FormGroup sx={{ marginBottom: "20px" }}>
              <TextField
                value={timeToVisit}
                onChange={(e) => {
                  if(new Date(e.target.value) >= new Date()){
                    setTimeToVisit(e.target.value)
                  }
                }}
                type={"datetime-local"}
                label={t("Suitable Date for Visit")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormGroup>

            <FormGroup sx={{ marginBottom: "30px" }}>
              <LocationSearchInput
                location={location}
                setLocation={setLocation}
              />
            </FormGroup>
            <FormGroup sx={{ marginBottom: "30px" }}>
              <TextField
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                label={t("Address")}
                variant="outlined"
              />
            </FormGroup>

            <FormGroup sx={{ marginBottom: "30px" }}>
              <TextField
                onChange={(e) => setFile(e.target.files[0])}
                label={t("Unit Plan")}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                type={"file"}
              />
            </FormGroup>
              </div>
            </ThemeProvider>
          </CacheProvider> 
          : 
          <div>
            <FormGroup sx={{ marginBottom: "20px" }}>
              <TextField
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                label={t("phone")}
                variant="outlined"
              />
            </FormGroup>
            <FormGroup sx={{ marginBottom: "20px" }}>
              <TextField
                value={timeToCall}
                onChange={(e) => {
                  if(new Date(e.target.value) >= new Date()){
                    setTimeToCall(e.target.value)
                  }
                }}
                placeholder="s"
                type="datetime-local"
                label={t("Suitable Time for Call")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormGroup>
            <FormGroup sx={{ marginBottom: "20px" }}>
              <TextField
                value={timeToVisit}
                onChange={(e) => {
                  if(new Date(e.target.value) >= new Date()){
                    setTimeToVisit(e.target.value)
                  }
                }}
                type={"datetime-local"}
                label={t("Suitable Date for Visit")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormGroup>

            <FormGroup sx={{ marginBottom: "30px" }}>
              <LocationSearchInput
                location={location}
                setLocation={setLocation}
              />
            </FormGroup>
            <FormGroup sx={{ marginBottom: "30px" }}>
              <TextField
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                label={t("Address")}
                variant="outlined"
              />
            </FormGroup>

            <FormGroup sx={{ marginBottom: "30px" }}>
              <TextField
                onChange={(e) => setFile(e.target.files[0])}
                label={t("Unit Plan")}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                type={"file"}
              />
            </FormGroup>
            </div>
          }
            
            
            {success && (
              <Alert sx={{ marginBottom: "20px" }} severity="success">
                {t("Sent Successfully!")}{" "}
                
              </Alert>
            )}
            {error && (
              <Alert sx={{ marginBottom: "20px" }} severity="error">
                {error}
              </Alert>
            )}
            {!success && <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button type={"submit"} variant="contained">
                {t("Apply for Site Visit")}
              </Button>
            </Box>}
            {success && <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button type={"button"} onClick={handleResetForm} variant="contained">
                {t("Back to HOme")}
              </Button>
            </Box>}
          </form>
        </Box>

        <Loader loading={loading} />
      </Paper>
    </Container>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDSq9zuGjjxWP37E3HPsO_fdt-sQgnwXmk",
  libraries: ["places"],
})(MakeVisit);
