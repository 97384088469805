import { Alert, Button, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../hooks/auth";
import "./Form.css"
import {useRef} from 'react'
import { useTranslation } from "react-i18next"
 

export default function Form(){

    const {t} = useTranslation()

    //////////

    let dispatch = useDispatch()
    
    let [loginError,setLoginError] = React.useState(false)

    let [emailError,setEmailError] = React.useState(false)
    let [passError,setPassError] = React.useState(false)

    let [emailTouched,setEmailTouched] = React.useState(false)
    let [passTouched,setPassTouched] = React.useState(false)

    let emailIn = useRef()
    let password = useRef()

    function emailChanged(event){

        setEmailTouched(true)

        let email = event.target.value
        
        if( email === '' ){
            setEmailError(true)
        }else{
    
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(email.match(mailformat))
            {
                setEmailError(false)
            }
            else
            {
                setEmailError(true)
            }
    
        }

    }

    function passChanged(event){

        setPassTouched(true)
        let pass = event.target.value
        
        if( pass === '' ){
            setPassError(true)
        }else{
            setPassError(false)
        }

    }

    let formIsValid = emailTouched && !emailError && !passError && passTouched ? true : false;

    function loginHandler(event){
        event.preventDefault()
        let email = emailIn.current.children[1].children[0].value
        let pass = password.current.children[1].children[0].value
        let reqObj = {
            email: email,
            password: pass
        }
        axios.post('https://api.10sap.com/api/login',reqObj).then(
            (response) => {
                if( response.data.success ){
                    localStorage.setItem('access_token',response.data.token)
                    localStorage.setItem('userData',JSON.stringify(response.data.user))
                    dispatch(authActions.loginHandler())
                    
                }else{
                    setLoginError(response.data.message)
                    setTimeout(() => {
                        setLoginError('')
                    },1500)
                }   
            }  
        )
          
    }


    return(

        <React.Fragment>
            
            <Grid container>

                <form onSubmit={loginHandler}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }}>

                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                            ref={emailIn}
                            onChange={emailChanged}
                            size="small"
                            margin="normal"
                            fullWidth 
                            type="email"
                            label={t("email")}
                            error={emailError}
                            helperText={emailError ? "Email Not Valid" : ""}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                            onChange={passChanged}
                            size="small"
                            margin="normal"
                            fullWidth 
                            ref={password}
                            type="password"
                            label={t("password")}
                            error={passError}
                            helperText={passError ? "Field Is Required" : ""}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <FormControlLabel control={<Checkbox size="small" defaultChecked />} label="Keep Me Logged In" />
                        </Grid>

                        {
                            loginError &&
                            <Grid item xs={12} md={12}>
                                <Alert severity="error">{loginError}</Alert>
                            </Grid>
                        }
                        
                        <Grid item xs={12} md={12}>
                            <div className="btnsCon">
                                <Button type="submit" size="normal" disabled={!formIsValid} variant="contained">{t("login")}</Button>
                            </div>     
                        </Grid>

                    </Grid>

                </form>
            </Grid>

        </React.Fragment>

    )

}