import FacebookLogin from "react-facebook-login";
import axios from "axios";
import { useDispatch } from "react-redux";
import { authActions } from "../../hooks/auth";
import React from "react";
import { Alert, Grid } from "@mui/material";
import { GoogleLogin } from "react-google-login";
import FacebookIcon from "@mui/icons-material/Facebook";
import "./SocialLogin.css";

export default function SocialLogin() {
  let [loginError, setLoginError] = React.useState(false);
  let dispatch = useDispatch();

  const responseFacebook = (response) => {
    let reqObj = {
      provider: "facebook",
      token: response.accessToken,
    };
    axios
      .post("https://api.10sap.com/api/social/login", reqObj)
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("access_token", response.data.token);
          localStorage.setItem("userData", JSON.stringify(response.data.user));

          dispatch(authActions.loginHandler());
        } else {
          setLoginError(response.data.message);
          setTimeout(() => {
            setLoginError("");
          }, 1500);
        }
      });
  };

  const onGoogleLoginSuccess = (res) => {
    let reqObj = {
      provider: "google",
      token: res.accessToken,
    };
    axios
      .post("https://api.10sap.com/api/social/login", reqObj)
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("access_token", response.data.token);
          localStorage.setItem("userData", JSON.stringify(response.data.user));
          dispatch(authActions.loginHandler());
        } else {
          setLoginError(response.data.message);
          setTimeout(() => {
            setLoginError("");
          }, 1500);
        }
      });
  };

  const onGoogleLoginFailure = (res) => {
    console.log("Login Failed:", res);
  };

  return (
    <div className="social-login">
      <FacebookLogin
        appId="753383826043410"
        fields="name,email,picture"
        callback={responseFacebook}
        icon={<FacebookIcon />}
      />
      <span>
        <GoogleLogin
          clientId="430041004356-pus2s4dsi1tonqmj6uala5a40ulfal4t.apps.googleusercontent.com"
          buttonText="LOG IN WITH GOOGLE"
          onSuccess={onGoogleLoginSuccess}
          onFailure={onGoogleLoginFailure}
          cookiePolicy={"single_host_origin"}
          className="googleBt"
        />
      </span>
      {loginError && (
        <Grid item xs={12} md={12}>
          <Alert severity="error">{loginError}</Alert>
        </Grid>
      )}
    </div>
  );
}
