import { FormControlLabel, Switch } from "@mui/material";
import { useContext } from "react";
import { LabelContext } from "../../hooks/InputFeild";
import { useTranslation } from "react-i18next";
import "./AcPlan.css";
export default function AcPlan() {
  const { t } = useTranslation();
  let choosenPlans = useContext(LabelContext).inputs.choosenPlans;
  let choosenRooms = useContext(LabelContext).inputs.unitRooms;
  let choosenAcRooms = useContext(LabelContext).inputs.airConditioners;
  let airConditionersWithReception =
    useContext(LabelContext).inputs.airConditionersWithReception;
  let handleChange = useContext(LabelContext)._handleChange;
  let handleReset = useContext(LabelContext).resetPlan;

  function handleChangeNum(roomKey, num) {
    let oldNum = choosenAcRooms[roomKey]
      ? parseInt(choosenAcRooms[roomKey])
      : 0;
    if (Object.keys(choosenPlans["ac"]).length > 0) {
      handleReset(roomKey, oldNum + num);
    } else {
      handleChange({
        airConditioners: { ...choosenAcRooms, [roomKey]: oldNum + num },
      });
    }
  }

  function handleCheckRoom(roomKey, index) {
    let oldChoosen = choosenAcRooms[roomKey] ? choosenAcRooms[roomKey] : [];

    if (Object.keys(choosenPlans["ac"]).length > 0) {
      handleReset(roomKey, index);
    } else {
      if (oldChoosen.includes(index)) {
        handleChange({
          airConditioners: {
            ...choosenAcRooms,
            [roomKey]: oldChoosen.filter((i) => i != index),
          },
        });
      } else {
        handleChange({
          airConditioners: {
            ...choosenAcRooms,
            [roomKey]: [...oldChoosen, index],
          },
        });
      }
    }
  }

  return (
    <div style={{ width: "100%", marginBottom: "20px", textAlign: "center" }}>
      {Object.keys(choosenRooms)
        .filter((categoryKey) => !["wc_guest", "wc"].includes(categoryKey))
        .map((categoryKey) => {
          return Object.keys(choosenRooms[categoryKey]).map((roomKey) => {
            if (choosenRooms[categoryKey][roomKey].num > 0) {
              return (
                <div
                  key={roomKey}
                  style={{
                    marginBottom: "40px",
                  }}
                  className="acRoomSelect"
                >
                  {Array(choosenRooms[categoryKey][roomKey].num)
                    .fill(0)
                    .map((r, index) => {
                      return (
                        <>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={choosenAcRooms[roomKey]?.includes(
                                  index
                                )}
                                onChange={() => {
                                  handleCheckRoom(roomKey, index);
                                }}
                              />
                            }
                            label={roomKey + " " + (parseInt(index) + 1)}
                            className="acRoomSelectChild"
                          />
                        </>
                      );
                    })}
                </div>
              );
            }
          });
        })}
      <div
        style={{ width: "100%", display: "flex", justifyContent: "end" }}
        className="acRoomSelectChild"
      >
        <FormControlLabel
          control={
            <Switch
              checked={airConditionersWithReception}
              onChange={() => {
                if (Object.keys(choosenPlans["ac"]).length > 0) {
                  handleReset(null, null, "toggle");
                } else {
                  handleChange({
                    airConditionersWithReception: !airConditionersWithReception,
                  });
                }
              }}
            />
          }
          label={t("Include Reception")}
        />
      </div>
    </div>
  );
}
