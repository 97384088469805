import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Form from "./form/Form";

import "../login/Left.css";

export default function Right(){

    return(
        
        <React.Fragment>
            <div className="container">

                <img src='/images/logo.png' className="logo" alt="" />
                
                <Typography variant="h6" className="title">
                    <span className="d-block">Welcome,</span><br />
                    <span>It only takes a few seconds to create your account.</span>
                </Typography>

                <Typography variant="p">
                    Already have an account?
                    <Link to="/login" className="text-primary">Login now</Link>
                </Typography>
                
                <Form />

            </div>
        </React.Fragment>

    )

}