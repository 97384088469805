import React, { useContext, useEffect } from "react";
import { LabelContext } from "./hooks/InputFeild";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MapLocation from "./steps/FirstStepUnitLocation";
import UnitType from "./steps/SecStepUnitType";
import Details from "./steps/ThirdStepDetails";
import UnitSpaces from "./steps/FourthStepUnitSpaces";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Paper from "@mui/material/Paper";
import { Container } from "@mui/material";
import Loader from "./loader/Loader";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

function getStepContent(step) {
  switch (step) {
    case 0:
      return <MapLocation />;
    case 1:
      return <UnitType />;
    case 2:
      return <Details />;
    default:
      return <UnitSpaces />;
  }
}

export default function Steps() {
  const lang = i18next.language;
  const { t } = useTranslation();
  const steps = [
    t("unitLocation"),
    t("UnitType"),
    t("Details"),
    t("UnitSpaces"),
  ];

  ////
  const [activeStep, setActiveStep] = React.useState(
    localStorage.getItem("activeMainStep")
      ? parseInt(localStorage.getItem("activeMainStep"))
      : 0
  );
  const value = useContext(LabelContext);

  let [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1600);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("activePlanStep")) {
      history.push(lang === "en" ? "/unit-plans" : "/ar/unit-plans");
    }
  }, []);

  const handleNext = () => {
    setLoading(true);

    setTimeout(() => {
      const newActive = activeStep + 1;
      localStorage.setItem("activeMainStep", newActive);
      setActiveStep(newActive);
    }, 500);

    setTimeout(() => {
      setLoading(false);
    }, 1600);
  };

  const handleBack = () => {
    setLoading(true);
    setTimeout(() => {
      const newActive = activeStep - 1;
      localStorage.setItem("activeMainStep", newActive);
      setActiveStep(newActive);
    }, 500);
    setTimeout(() => {
      setLoading(false);
    }, 1600);
  };

  let history = useHistory();

  function finishSteps() {
    history.push(lang === "en" ? "/unit-plans" : "/ar/unit-plans");
    localStorage.setItem("activePlanStep", 0);
  }

  const disablebtn = () => {
    let btnDisbaled = true;

    console.log(activeStep, parseFloat(value.inputs.details.unitArea))
    if (activeStep === 0) {
      btnDisbaled = value.inputs.area_id;
    } else if (activeStep === 1) {
      let chosenUnitType = value.inputs.selectedUnitType;
      btnDisbaled = chosenUnitType;
    } else if (activeStep === 2) {
      btnDisbaled =
        (value.inputs.details.manyFloor < 6) &&
        ( parseFloat(value.inputs.details.unitArea) >= 80);
    }

    return btnDisbaled;
  };

  return (
    <Container maxWidth="lg">
      <Paper className="PaperMain loaderContainer" elevation={3}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step className="stepNum" key={label} {...stepProps}>
                <StepLabel {...labelProps} className="step-icon">
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }} component="div">
            {getStepContent(activeStep)}
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              variant="contained"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              {t("Back")}
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            {activeStep === steps.length - 1 ? (
              <Button
                variant="contained"
                disabled={!disablebtn()}
                onClick={finishSteps}
              >
                {t("Finish")}
              </Button>
            ) : (
              <Button
                variant="contained"
                disabled={!disablebtn()}
                onClick={handleNext}
              >
                {t("Next")}
              </Button>
            )}
          </Box>
        </React.Fragment>

        <Loader loading={loading} />
      </Paper>
    </Container>
  );
}
