import { Grid } from "@mui/material";
import Left from "../login/Left";
import Right from "./Right";

import "../login/Login.css"

export default function Register(){


    return(

        <Grid container className="register authContainer">

            <Grid item xs={12} md={4} className="left">
                <Left />
            </Grid>

            <Grid item xs={12} md={8} className="right">
                <Right />
            </Grid>
        
        </Grid>

    )

}