import React, { useState, createContext, useEffect } from "react";
import { DATA } from "./constant";
export const LabelContext = createContext();

export const UseForm = (props) => {
  const [inputs, setInputs] = useState(
    JSON.parse(localStorage.getItem("inputs"))
      ? JSON.parse(localStorage.getItem("inputs"))
      : DATA
  );

  function updateInputs(newData) {
    setInputs((oldinputs) => newData);
    localStorage.setItem("inputs", JSON.stringify(newData));
  }

  const handleChange = (prop) => (event) => {
    updateInputs({
      ...inputs,
      details: { ...inputs.details, [prop]: event.target.value },
      [prop]: event.target.value,
    });
  };

  function changeFloorsNum(value) {

    updateInputs({
      ...inputs,
      details: { ...inputs.details, manyFloor: value },
    });
  }

  const _handleChange = (newData) => {
    updateInputs({
      ...inputs,
      ...newData,
    });
  };

  const ChangeunitType = (type) => {
    updateInputs({
      ...inputs,
      selectedUnitType: type.id,
      selectedUnitTypeName: type.value,
      selectedUnitTypeFactor: parseFloat(type.ratio)
    });
  };

  const ChangeRooms = (option, value) => {
    let actualArea = inputs.details.unitArea * inputs.details.ActualAreaPercent;

    if(['MasterBedroom', 'MasterBathroom'].includes(option.id)){
      updateInputs({
        ...inputs,
        unitRooms: { 
          ...inputs.unitRooms,
          room: { 
            ...inputs.unitRooms.room,
            MasterBedroom: {
              ...inputs.unitRooms.room.MasterBedroom,
              num: value, 
            } 
          },
          wc: { 
            ...inputs.unitRooms.wc,
            MasterBathroom: {
              ...inputs.unitRooms.wc.MasterBathroom,
              num: value, 
            } 
          } 
        },
      });
    }else{
      updateInputs({
        ...inputs,
        unitRooms: {
          ...inputs.unitRooms,
          [option.category]: {
            ...inputs.unitRooms[option.category],
            [option.id]: {
              num: value,
              roomObj: option,
              area: option.fixed
                ? option.fixedArea
                : option.percentage * actualArea,
            },
          },
        },
      });
    }
  };

  function getTotalRoomCategory(obj) {
    var sum = 0;
    //var obj = inputs.unitRooms[category]
    for (var el in obj) {
      if (obj.hasOwnProperty(el)) {
        sum += parseFloat(obj[el].num);
      }
    }
    return sum;
  }

  function getReceptionArea(actualArea, rooms) {
    Object.keys(rooms).map((category) => {
      Object.keys(rooms[category]).map((roomKey) => {
        const roomObj = rooms[category][roomKey];
        actualArea -= roomObj.num * roomObj.area;
      });
    });

    return actualArea;
  }

  function calculate(
    step,
    plan,
    area,
    rooms,
    numOfFloors,
    selectedUnitTypeFactor = inputs.selectedUnitTypeFactor,
    area_factor = inputs.area_factor,
    airConditioners = inputs.airConditioners,
    airConditionersWithReception = inputs.airConditionersWithReception
  ) {
    let actualArea = area * inputs.details.ActualAreaPercent;
    //let walls = Math.round(actualArea * 0.0572);
    let totalRooms = getTotalRoomCategory(rooms["room"]);
    let totalWc = getTotalRoomCategory(rooms["wc"]);
    let totalwc_guest = getTotalRoomCategory(rooms["wc_guest"]);

    let totalKitchen = getTotalRoomCategory(rooms["kitchen"]);
    let totalDoors = totalRooms + totalKitchen + totalWc+totalwc_guest;
    let planPrice = parseInt(plan.package_price);

    switch (step) {
      case "design": // design
        return planPrice * actualArea * selectedUnitTypeFactor * area_factor;
      case "electrical": // elec
        return planPrice * actualArea * selectedUnitTypeFactor * area_factor;
      case "plumbing": // plumbing;
        return planPrice * (totalWc+totalwc_guest) * selectedUnitTypeFactor * area_factor;
      default: // ac
        let z = 0;

        let allRooms = {};

        Object.keys(rooms).map((categoryKey) => {
          allRooms = { ...allRooms, ...rooms[categoryKey] };
        });

        Object.keys(airConditioners).map((roomKey) => {
          z +=
            airConditioners[roomKey].length * allRooms[roomKey].area
            // * allRooms[roomKey].roomObj.air_conditioner_ratio;
        });

        if (airConditionersWithReception) {
          //z += 3;
          z += getReceptionArea(actualArea, rooms)
        }

        return planPrice * z * selectedUnitTypeFactor * area_factor;
      case "plastering": // plastering
        let part1 = actualArea * plan.walls_factor + actualArea;
        return part1 * planPrice * selectedUnitTypeFactor * area_factor;
      case "gypsum": // gypsum
        let percentage = plan.percentage_of_area / 100;
        return planPrice * percentage * actualArea * selectedUnitTypeFactor * area_factor;
      case "paints": // paint
        let part2 = actualArea * plan.walls_factor + actualArea;
        return part2 * planPrice * selectedUnitTypeFactor * area_factor;
      case "flooring": // floor
        return planPrice * actualArea * selectedUnitTypeFactor * area_factor; 
      case "mainDoor": // main door
        return planPrice * selectedUnitTypeFactor * area_factor;
      case "doorsInterior": // doors inter
        return planPrice * totalDoors * selectedUnitTypeFactor * area_factor;
      case "skirting": // main door
        return planPrice * actualArea * selectedUnitTypeFactor * area_factor;
      case "bathrooms_and_kitchen": // doors inter
        let total = 0;
        for (const key in rooms.kitchen) {
          if (rooms.kitchen[key].roomObj.fixed) {
            total +=
              rooms.kitchen[key].roomObj.fixedArea *
              rooms.kitchen[key].num *
              rooms.kitchen[key].roomObj.height_factor;
          } else {
            total +=
              rooms.kitchen[key].roomObj.percentage *
              actualArea *
              rooms.kitchen[key].num *
              rooms.kitchen[key].roomObj.height_factor;
          }
        }
        for (const key in rooms.wc) {
          if (rooms.wc[key].roomObj.fixed) {
            total +=
              rooms.wc[key].roomObj.fixedArea *
              rooms.wc[key].num *
              rooms.wc[key].roomObj.height_factor *
              rooms.wc[key].roomObj.parameter_factor;
          } else {
            total +=
              rooms.wc[key].roomObj.percentage *
              actualArea *
              rooms.wc[key].num *
              rooms.wc[key].roomObj.height_factor *
              rooms.wc[key].roomObj.parameter_factor;
          }
        }
        for (const key in rooms.wc_guest) {
          if (rooms.wc_guest[key].roomObj.fixed) {
            total +=
              rooms.wc_guest[key].roomObj.fixedArea *
              rooms.wc_guest[key].num *
              rooms.wc_guest[key].roomObj.height_factor *
              rooms.wc_guest[key].roomObj.parameter_factor;
          } else {
            total +=
              rooms.wc_guest[key].roomObj.percentage *
              actualArea *
              rooms.wc_guest[key].num *
              rooms.wc_guest[key].roomObj.height_factor *
              rooms.wc_guest[key].roomObj.parameter_factor;
          }
        }
        return planPrice * total * selectedUnitTypeFactor * area_factor;
      case "stairs":
        return (numOfFloors - 1) * planPrice * 20 * selectedUnitTypeFactor * area_factor;
      case "toilet_and_basin":
        let y = 0;
        Object.keys(rooms.wc).map((r) => {
          y += rooms.wc[r].num;
        });
        Object.keys(rooms.wc_guest).map((r) => {
          y += rooms.wc_guest[r].num;
        });
        return planPrice * y * selectedUnitTypeFactor * area_factor;
      case "bathtub":
        let x = 0;
        Object.keys(rooms.wc).map((r) => {
          x += rooms.wc[r].num;
        });
        return planPrice * x * selectedUnitTypeFactor * area_factor;
      case "architectural":
        return planPrice * actualArea * 0.06 * 3.2 * selectedUnitTypeFactor * area_factor;
      case "reception_flooring":
        return planPrice * getReceptionArea(actualArea, rooms) * selectedUnitTypeFactor * area_factor;
      case "rooms_flooring":
        return planPrice * (actualArea - getReceptionArea(actualArea, rooms)) * selectedUnitTypeFactor * area_factor;
    }
  }

  function choosePlan(plan, step) {
    let prevPlanPrice = 0;
    if (Object.keys(inputs.choosenPlans[step]).length > 0) {
      prevPlanPrice = calculate(
        step,
        inputs.choosenPlans[step],
        inputs.details.unitArea,
        inputs.unitRooms,
        inputs.details.manyFloor
      );
    }
    updateInputs({
      ...inputs,
      choosenPlans: { 
        ...inputs.choosenPlans, 
        [step]: {
          ...plan, 
          invoicePrice: calculate(
            step,
            plan,
            inputs.details.unitArea,
            inputs.unitRooms,
            inputs.details.manyFloor
          )
        } 
      },
      totalPrice:
        inputs.totalPrice -
        prevPlanPrice +
        calculate(
          step,
          plan,
          inputs.details.unitArea,
          inputs.unitRooms,
          inputs.details.manyFloor
        ),
    });
  }

  function resetPlan(roomKey, value, toggleSwitch) {
    let prevPlanPrice = calculate(
      "ac",
      inputs.choosenPlans["ac"],
      inputs.details.unitArea,
      inputs.unitRooms,
      inputs.details.manyFloor
    );

    let oldChoosen = inputs.airConditioners[roomKey]
      ? inputs.airConditioners[roomKey]
      : [];

    updateInputs({
      ...inputs,
      choosenPlans: { ...inputs.choosenPlans, ac: {} },
      totalPrice: inputs.totalPrice - prevPlanPrice,
      airConditioners: roomKey
        ? {
            ...inputs.airConditioners,
            [roomKey]: oldChoosen.includes(value)
              ? oldChoosen.filter((i) => i != value)
              : [...oldChoosen, value],
          }
        : inputs.airConditioners,
      airConditionersWithReception: toggleSwitch
        ? !inputs.airConditionersWithReception
        : inputs.airConditionersWithReception,
    });
  }

  function handlePlastering(val, plan) {
    if (val) {
      updateInputs({
        ...inputs,
        plasteringChoice: val,
        choosenPlans: { 
          ...inputs.choosenPlans, 
          plastering: {
            ...plan,
            invoicePrice: calculate(
              "plastering",
              plan,
              inputs.details.unitArea,
              inputs.unitRooms,
              inputs.details.manyFloor
            )
          } 
        },
        totalPrice:
          inputs.totalPrice +
          calculate(
            "plastering",
            plan,
            inputs.details.unitArea,
            inputs.unitRooms,
            inputs.details.manyFloor
          ),
      });
    } else {
      let prevPlanPrice = 0;

      if (Object.keys(inputs.choosenPlans["plastering"]).length > 0) {
        prevPlanPrice = calculate(
          "plastering",
          plan,
          inputs.details.unitArea,
          inputs.unitRooms,
          inputs.details.manyFloor
        );
      }
      updateInputs({
        ...inputs,
        plasteringChoice: val,
        choosenPlans: { ...inputs.choosenPlans, plastering: {} },
        totalPrice: inputs.totalPrice - prevPlanPrice,
      });
    }
  }

  return (
    <>
      <LabelContext.Provider
        value={{
          inputs,
          changeFloorsNum,
          handleChange,
          ChangeRooms,
          ChangeunitType,
          _handleChange,
          handlePlastering,
          choosePlan,
          resetPlan,
          calculate,
          setInputs,
          updateInputs,
        }}
      >
        {props.children}
      </LabelContext.Provider>
    </>
  );
};
