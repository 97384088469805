import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../hooks/auth";
import { useContext, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { DATA } from "../hooks/constant";
import { LabelContext } from "../hooks/InputFeild";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const pages = ["Products", "Pricing", "Blog"];

function ResponsiveAppBar({ isLogged }) {
  ////
  const router = useHistory();
  const value = useContext(LabelContext);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  function handleResetForm() {
    value.updateInputs(DATA);
    localStorage.removeItem("activePlanStep");
    localStorage.removeItem("inputs");
    localStorage.removeItem("activeMainStep");
    router.push(createUnitUrl);
    document.location.reload();
  }
  let dispatch = useDispatch();

  ////////// lang
  const params = useLocation();
  const [lang, setLang] = useState("");
  const [current, setCurrent] = useState("");

  useEffect(() => {
    let loc = params.pathname.split("/");
    if (loc[1] === "ar") {
      i18next.changeLanguage("ar");
      setLang("ar");

      loc = "/" + loc[2] + (loc[3] ? '/'+loc[3] : '');
    } else {
      i18next.changeLanguage("en");
      setLang("");
      loc = "/ar/" + loc[1] + (loc[2] ? '/'+loc[2] : '');
    }
    setCurrent(loc);
  }, [params]);

  const { t } = useTranslation();

  ////

  const createUnitUrl = (lang === "ar" ? "/" + lang : "") + "/create-unit";


  function isJson() {
    try {
        JSON.parse(localStorage.getItem('userData'));
    } catch (e) {
        return false;
    }
    return true;
  }

  return (
    <AppBar
      position="static"
      className="toolbar"
      sx={{
        backgroundColor: "#ffffff",
        marginBottom: "80px",
        display: isLogged ? "" : "none",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <a href="https://10sap.com/">
            <Box
              className="logo"
              component="img"
              sx={{
                display: { xs: "none", md: "flex" },
              }}
              alt="logo"
              src="/images/logo.png"
            />
          </a>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleResetForm}>
                <Typography textAlign="center">{"Add Unit"}</Typography>
              </MenuItem>
              <MenuItem onClick={handleResetForm}>
                <Typography textAlign="center">{"Reset"}</Typography>
              </MenuItem>
              {lang === "" && (
                <MenuItem
                  onClick={() => router.push(current)}
                >
                  <Typography textAlign="center">AR</Typography>
                </MenuItem>
              )}
              {lang === "ar" && (
                <MenuItem
                  onClick={() => router.push(current)}
                >
                  <Typography textAlign="center">EN</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="https://10sap.com/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            10 SAP
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              onClick={handleResetForm}
              sx={{ my: 2, color: "black", display: "block" }}
            >
              {t("addUnit")}
            </Button>

            <Button
              onClick={handleResetForm}
              sx={{ my: 2, color: "black", display: "block" }}
            >
              {t("reset")}
            </Button>
            {lang === "" && (
              <Button
                onClick={() => router.push(current)}
                sx={{ my: 2, color: "black", display: "block" }}
              >
                <Typography textAlign="center">AR</Typography>
              </Button>
            )}
            {lang === "ar" && (
              <Button
                onClick={() => router.push(current)}
                sx={{ my: 2, color: "black", display: "block" }}
              >
                <Typography textAlign="center">EN</Typography>
              </Button>
            )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                onClick={handleOpenUserMenu}
                className="avatar-toolbar"
                sx={{ backgroundColor: "#fcd10d" }}
              >
                <Avatar
                  alt={
                    localStorage.getItem("userData") && isJson()
                      ? JSON.parse(
                          localStorage.getItem("userData")
                        ).name?.toUpperCase()
                      : ""
                  }
                  src="/static/images/avatar/2.jpg"
                  sx={{ backgroundColor: "#111111" }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                onClick={() => {
                  router.push((lang === "ar" ? "/" + lang : "") + "/profile");
                }}
              >
                <Typography textAlign="center">{t("My Profile")}</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  router.push(
                    (lang === "ar" ? "/" + lang : "") + "/user-units"
                  );
                }}
              >
                <Typography textAlign="center">{t("My Units")}</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  dispatch(authActions.logoutHandler());
                }}
              >
                <Typography textAlign="center">{t("Logout")}</Typography>
              </MenuItem>
              {lang === "" && (
                <MenuItem
                  onClick={() => router.push(current)}
                >
                  <Typography textAlign="center">AR</Typography>
                </MenuItem>
              )}
              {lang === "ar" && (
                <MenuItem
                  onClick={() => router.push(current)}
                >
                  <Typography textAlign="center">EN</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
