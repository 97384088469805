import axios from "axios";
import i18next from "i18next";

const baseUrl = "https://api.10sap.com/api";

export const getUnitTypes = () => {
  let head = "Bearer " + localStorage.getItem('access_token')

  const headers = {
    headers: {
      "Accept-Language": i18next.language,
      Authorization: head
    },
  };
  return axios.get(baseUrl + "/units", headers);
};

export const getPlaces = () => {
  let head = "Bearer " + localStorage.getItem('access_token')

  const headers = {
    headers: {
      "Accept-Language": i18next.language,
      Authorization: head
    },
  };
  return axios.get(baseUrl + "/areas", headers);
};

export const getRoomTypes = () => {
  let head = "Bearer " + localStorage.getItem('access_token')

  const headers = {
    headers: {
      "Accept-Language": i18next.language,
      Authorization: head
    },
  };
  return axios.get(baseUrl + "/rooms", headers);
};

export const getPlans = (type) => {
  let head = "Bearer " + localStorage.getItem('access_token')

  const headers = {
    headers: {
      "Accept-Language": i18next.language,
      Authorization: head
    },
  };
  return axios.get(baseUrl + "/types/" + type, headers);
};

export const getDesc = (type) => {
  let head = "Bearer " + localStorage.getItem('access_token')

  const headers = {
    headers: {
      "Accept-Language": i18next.language,
      Authorization: head
    },
  };
  return axios.get(baseUrl + "/types/" + type + "/description", headers);
};


export const makeVisit = (data) => {
  let head = "Bearer " + localStorage.getItem('access_token')

  let headers = {headers: {Authorization: head}}
  return axios.post(baseUrl + "/siteVisit/store", data, headers);
};


export const deleteAcc = () => {
  let head = "Bearer " + localStorage.getItem('access_token')

  let headers = {headers: {Authorization: head}}
  return axios.post(baseUrl + "/delete-account", {}, headers);
};

export const getPrivacyPilcies = async () => {

  return axios.get(baseUrl+'/home/terms-and-conditions')
}