import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { UnitsList } from "./UnitsList";
import Paper from '@mui/material/Paper';
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../loader/Loader";
import { useTranslation } from "react-i18next";


export default function Units(){
    const {t} = useTranslation()

    let [userUnits,setUserUnits] = useState([]);
    let [loading,setLoading] = useState(true);

    // user units
    useEffect(() => {

        let head = "Bearer " + localStorage.getItem('access_token')

        let headers = {headers: {Authorization: head}}

        axios.get('https://api.10sap.com/api/userUnit/all',headers).then((response) => {
            setUserUnits(response.data.UserUnits) 
            setLoading(false)
        });
        
    },[]);

    return (
        <Container maxWidth="lg">
            <Paper className="PaperMain loaderContainer" elevation={3}>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography sx={{marginTop: '0px !important'}} textAlign= "center" className="mr" gutterBottom variant="h4" component="h4">
                        {t("My Units")}
                    </Typography>
                    <UnitsList userUnits={userUnits} />
                </Box>
                <Loader loading={loading} />
            </Paper>
        </Container>
    )
}