import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect } from "react";
import Plan from "./Plan";
import { LabelContext } from "../../hooks/InputFeild";
import { useState } from "react";
import { getDesc } from "../../../api/api";
import Loader from "../../loader/Loader";
import PlasteringStep from "./PlasteringStep";
import AcPlan from "./AcPlan";
import { useTranslation } from "react-i18next";
export default function PlanStep(props) {
  let labelContext = useContext(LabelContext);
  const { t } = useTranslation();
  const [plans, setPlans] = useState([]);
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getDesc(props.api).then(
      (res) => {
        setPlans(res.data.packages);
        setDesc(res.data.description ? res.data.description : "");
        setLoading(false);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [props.api]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div style={{ opacity: loading ? "0" : "1" }}>
        <Typography
          textAlign="center"
          className="mr price-title"
          gutterBottom
          variant="h5"
          component="h5"
          
        >
          <span className="price">{t("Total Price") + " : "}</span>
          {Math.round(labelContext.inputs.totalPrice).toLocaleString("en-US", {
            maximumFractionDigits: 2,
          })}
          {" " + t("EGP")}
        </Typography>
        <Typography
          textAlign="center"
          className="mr title-step"
          gutterBottom
          variant="h5"
          component="h5"
          sx={{fontWeight: "bold"}}
        >
          {props.title}
        </Typography>
        <Typography
          className="mr text-center mb-20 desc"
          margin="normal"
          variant="body2"
          color="text.secondary"
        >
          {desc}
        </Typography>
        {props.step === "plastering" && <PlasteringStep plans={plans} />}

        {!["plastering"].includes(props.step) && (
          <>
            {props.step === "ac" && <AcPlan />}

              {plans.map((plan) => {
                return (
                  <Plan
                      key={props.step + "-" + plan.package_id}
                      choosen={
                        plan.package_id ===
                        labelContext.inputs.choosenPlans[props.step][
                          "package_id"
                        ]
                          ? true
                          : false
                      }
                      data={plan}
                      step={props.step}
                    />
                );
              })}
            
          </>
        )}
      </div>
      <Loader loading={loading} />
    </Box>
  );
}
