import { Grid, Typography } from "@mui/material";

import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import "./UnitsList.css";
import DownloadIcon from "@mui/icons-material/Download";
import { useTranslation } from "react-i18next";

export function UnitsList(props) {
  const {t} = useTranslation()
  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <List>
        <ListItem className="myListItem headItem">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {t("Unit Location")}
            </Grid>
            <Grid item xs={1} className="centerItem">
              {t("UnitType")}
            </Grid>
            <Grid item xs={1} className="centerItem">
              {t("Area")}
            </Grid>
            <Grid item xs={2} className="centerItem">
              {t("Date")}
            </Grid>
            <Grid item xs={2} className="centerItem">
              {t("Invoice")}
            </Grid>
          </Grid>
        </ListItem>

        {props.userUnits.length > 0 &&
          props.userUnits.map((unit) => {
            return (
              <ListItem className="myListItem" key={unit.id}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {unit.data.area_name}
                  </Grid>
                  <Grid item xs={1} className="centerItem">
                    {unit.data.selectedUnitTypeName}
                  </Grid>
                  <Grid item xs={1} className="centerItem">
                    {unit.data.area_name}
                  </Grid>
                  <Grid item xs={2} className="centerItem">
                    {unit.data.date}
                  </Grid>
                  <Grid item xs={2} className="centerItem">
                    <Link to={"invoice/" + unit.id} className="invoiceLink">
                      <DownloadIcon />
                      {t("View")}
                    </Link>
                  </Grid>
                </Grid>
              </ListItem>
            );
          })}
        {props.userUnits.length === 0 && (
          <Typography
            textAlign="center"
            className="mr"
            gutterBottom
            variant="h5"
            component="h5"
          >
            {t("No Units Yet")}
          </Typography>
        )}
      </List>
    </Box>
  );
}
