import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getPlaces } from "../../api/api";
import "./firststep.css";
import { LabelContext } from "../hooks/InputFeild";
import { useTranslation } from "react-i18next";
const MapLocation = () => {
  const value = useContext(LabelContext);
  const { t } = useTranslation();
  const [places, setPlaces] = useState(null);

  useEffect(() => {
    getPlaces().then(
      (res) => {
        setPlaces(res.data.Areas);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  function handleChooseArea(place) {
    value._handleChange({
      ...value.inputs,
      area_id: place.id,
      area_name: place.name,
      area_factor: place.rate,
    });
  }

  return (
    <Box component="form" noValidate autoComplete="off">
      <Typography
        className="mr title-step"
        sx={{ textAlign: "center", width: "100%" }}
        gutterBottom
        variant="h5"
        component="h5"
      >
        {t("locateUnit")}
      </Typography>
      <Grid container spacing={2}>
        {places &&
          places.map((p) => {
            return (
              <Grid
                key={p.id}
                item
                xs={12}
                sm={3}
                md={3}
                onClick={() => {
                  handleChooseArea(p);
                }}
              >
                <div
                  className={
                    value.inputs.area_id === p.id ? "areaBox active" : "areaBox"
                  }
                >
                  <img
                    //src={process.env.PUBLIC_URL + "/images/areas.jpg"}
                    src={p.background}
                    style={{
                      width: "100%",
                      height: "230px",
                      objectFit: "cover",
                    }}
                    alt={"area"}
                  />
                  <div className="bg">
                    <div className="activeBorders">
                      <span className="top"></span>
                      <span className="right"></span>
                      <span className="bottom"></span>
                      <span className="left"></span>
                    </div>
                    {/*<h4 style={{ color: "white" }}>{p.name}</h4>*/}
                  </div>
                </div>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

export default MapLocation;
