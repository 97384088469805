export const DATA = {
  area_id: null,
  area_name: null,
  area_factor: null,
  selectedUnitType: null,
  selectedUnitTypeName: null,
  selectedUnitTypeFactor: null,
  unitRooms: {
    wc: {},
    kitchen: {},
    room: {},
    wc_guest: {},
  },
  details: {
    unitArea: null,
    manyFloor: null,
    ActualAreaPercent: 0.8,
  },
  choosenPlans: {
    design: {},
    electrical: {},
    ac: {},
    doorsInterior: {},
    flooring: {},
    gypsum: {},
    plastering: {},
    paints: {},
    mainDoor: {},
    plumbing: {},
    skirting: {},
    bathrooms_and_kitchen: {},
    stairs: {},
    toilet_and_basin: {},
    bathtub: {},
    architectural: {},
    reception_flooring: {},
    rooms_flooring: {},
  },
  totalPrice: 0,
  plasteringChoice: false,
  airConditioners: {},
  airConditionersWithReception: false,
};
